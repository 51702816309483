<template>
  <!-- 小程序配置 -->
  <el-card class="box-card">
    <div class="shade" v-if="showShade">分店无权限更改小程序配置！</div>
    <el-page-header
      class="product-header"
      @back="goBack"
      content="小程序配置"
    ></el-page-header>
    <div
      class="contain"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <div class="swiper_image">
        <h3>
          轮播商品<span style="font-size: 12px; color: #909399"
            >(最多添加五个商品，最少一个)</span
          >
        </h3>
        <ol>
          <li
            v-for="(item, index) in wxSet.swiperProduct"
            :key="index"
            class="product_list"
          >
            <span>商品名称：</span>{{ item.title }} <span>商品价格：</span
            >{{ item.outPrice }} <span>商品ID：</span>{{ item.productID }}
            <span>合同编号：</span>{{ item.productNo }}
            <span
              @click="files_D(item)"
              class="product_d el-icon-circle-close"
            ></span>
          </li>
        </ol>
        <div class="swiper_add_imag">
          <el-input
            style="width: 100%"
            placeholder="请输入商品ID"
            v-model="searchProduct"
            clearable
          >
          </el-input>
          <el-button class="search_button" type="success" @click="searchButton"
            >添加商品</el-button
          >
        </div>
        <h3>
          轮播图片<span style="font-size: 12px; color: #909399"
            >(图片与商品对应)</span
          >
        </h3>
        <div class="swiper_img">
          <div
            class="swiper_img_list"
            v-for="(item, index) in wxSet.swiperImageInfo"
            :key="index"
          >
            <span
              @click="image_D(item)"
              class="swiper_icon el-icon-error"
            ></span>
            <img :src="item.imageUrl" alt="" />
          </div>
        </div>
        <input type="file" @change="onchangemd" />
        <h3>
          商家公告<span style="font-size: 12px; color: #909399"
            >(建议添加两条以上)</span
          >
        </h3>
        <el-input
          type="textarea"
          autosize
          style="width: 60%"
          placeholder="请输入公告标题"
          v-model="notice.noticeTitle"
        >
        </el-input>
        <div style="margin: 20px 0"></div>
        <el-input
          type="textarea"
          style="width: 60%"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入公告内容"
          v-model="notice.noticeBody"
        >
        </el-input>
        <ul>
          <li
            class="notice_list"
            v-for="(item, index) in wxSet.notice"
            :key="index"
          >
            <h4>
              <span></span> 标题： {{ item.noticeTitle }} 内容：
              <span>{{ item.noticeBody }}</span>
              <span
                class="el-icon-close notice_D"
                @click="notice_D(item)"
              ></span>
            </h4>
          </li>
        </ul>
        <el-button style="margin-top: 10px" type="success" @click="addNotice"
          >添加公告</el-button
        >
      </div>
    </div>
    <el-button class="set_success" @click="wxSetSuccess" type="warning"
      >保存小程序配置</el-button
    >
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      user_key: "",
      showShade: false,
      searchProduct: "",
      product_list: "",
      loading: true,
      notice: {
        noticeTitle: "",
        noticeBody: "",
      },
      wxSet: {
        swiperProduct: [],
        swiperImageInfo: [],
        notice: [],
      },
    };
  },
  created() {
    this.user_key = secret.Decrypt(localStorage.getItem("user_key"));
    const swiper_review = true;
    axios.post(this.GLOBAL.api + "/wx/set", { swiper_review }).then((res) => {
      const swiperImageInfoVAL = JSON.parse(res.data[0].swiper_image);
      const swiperImageInfoBlo = swiperImageInfoVAL[0].imageUrl.split(
        this.GLOBAL.file
      )
        ? true
        : false;

      const swiperImageInfoSPLIT = [];
      swiperImageInfoVAL.map((res, index) => {
        if (res.imageUrl.split(this.GLOBAL.file).length === 1) {
          swiperImageInfoSPLIT.push({
            imageUrl: this.GLOBAL.file + res.imageUrl.split("upload")[1],
            id: index,
          });
        } else {
          swiperImageInfoSPLIT.push({
            imageUrl: res.imageUrl,
            id: index,
          });
        }
      });
      this.wxSet.swiperImageInfo = swiperImageInfoSPLIT;

      this.product_list = JSON.parse(res.data[0].swiper_product);
      this.wxSet.notice = JSON.parse(res.data[0].swiper_notice);
      if (this.product_list) {
        this.product_list.forEach((productId) => {
          axios
            .post(this.GLOBAL.api + "/product/info", { productId })
            .then((para) => {
              this.wxSet.swiperProduct.push(para.data[0]);
            });
          this.loading = false;
        });
      }
    });
  },
  mounted() {
    // this.userKye === 'yhl001656847198012'
    if (this.user_key !== "yhl001656847198012") {
      this.showShade = true;
    }
  },
  // 监听器
  watch: {},
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    searchButton() {
      let productId = this.searchProduct;
      if (this.wxSet.swiperProduct.length > 4) {
        this.$message({
          type: "worning",
          message: "轮播商品最多五个，请删除后再添加",
        });
      } else {
        axios
          .post(this.GLOBAL.api + "/product/info", { productId })
          .then((res) => {
            if (res.data.length >= 1 && res.status === 200) {
              this.wxSet.swiperProduct.push(res.data[0]);
              this.searchProduct = "";
            } else {
              this.$message({
                type: "worning",
                message: "商品ID输入错误或商品不存在！",
              });
            }
          });
      }
    },
    files_D(e) {
      this.wxSet.swiperProduct = this.wxSet.swiperProduct.filter((item) => {
        return item.productID !== e.productID;
      });
    },
    // delete image
    image_D(e) {
      this.wxSet.swiperImageInfo = this.wxSet.swiperImageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    // update image
    onchangemd(e) {
      if (this.wxSet.swiperProduct.length > this.wxSet.swiperImageInfo.length) {
        this.disabledImage = true;
        const files = e.target.files[0];
        const isLt5M = +files.size > 5242880 ? true : false;
        if (isLt5M) {
          this.$message({
            type: "worning",
            message: "图片大小不允许大于5M！",
          });
          this.disabledImage = false;
        } else if (
          files.type === "image/png" ||
          files.type === "image/jpeg" ||
          files.type === "image/jpg"
        ) {
          let formdata = new FormData();
          formdata.append("uploadFile", files);
          axios({
            method: "post",
            url: this.GLOBAL.file,
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            this.wxSet.swiperImageInfo.push({
              id: this.wxSet.swiperImageInfo.length,
              imageName: files.name,
              imageUrl: this.GLOBAL.file + res.data.split("upload")[1],
            });
          });
          this.$message({
            type: "success",
            message: "上传成功",
          });
          this.disabledImage = false;
        } else {
          this.$message({
            type: "worning",
            message: "图片格式不正确",
          });
          this.disabledImage = false;
        }
      } else {
        this.$message({
          type: "worning",
          message: "轮播图片不能大于商品数量！",
        });
      }
    },
    addNotice() {
      if (this.notice.noticeTitle === "" || this.notice.noticeBody === "") {
        this.$message({
          type: "error",
          message: "公告标题或公告内容不允许为空！",
        });
      } else {
        this.wxSet.notice.push({
          id: this.wxSet.notice.length,
          noticeTitle: this.notice.noticeTitle,
          noticeBody: this.notice.noticeBody,
        });
        this.notice = {
          noticeTitle: "",
          noticeBody: "",
        };
      }
    },
    wxSetSuccess() {
      if (this.wxSet.swiperProduct.length < 1) {
        this.$message({
          type: "worning",
          message: "请最少添加一个轮播商品",
        });
      } else if (
        this.wxSet.swiperImageInfo.length !== this.wxSet.swiperProduct.length
      ) {
        this.$message({
          type: "worning",
          message: "请对应轮播商品和轮播商品图片，保持数量一致！",
        });
      } else if (
        //   更改
        this.wxSet.notice.noticeTitle === [] ||
        this.wxSet.notice.noticeBody === []
      ) {
        this.$message({
          type: "worning",
          message: "请设置公告",
        });
      } else {
        const productID = [];
        this.wxSet.swiperProduct.forEach((res) => {
          productID.push(res.productID);
        });
        const swiper = true;
        const swiper_img_notice = this.wxSet;
        if (this.user_key === "yhl001656847198012") {
          axios
            .post(this.GLOBAL.api + "/wx/set", {
              swiper,
              swiper_img_notice,
              productID,
            })
            .then((res) => {
              if (res.status === 200 && res.data.statusCode === 200) {
                this.$message({
                  type: "success",
                  message: "小程序设置成功！",
                });
              } else {
                this.$message({
                  type: "error",
                  message: "小程序设置失败，请刷新重试。",
                });
              }
            });
        }
      }
    },
    notice_D(e) {
      this.wxSet.notice = this.wxSet.notice.filter((item) => {
        return item.id !== e.id;
      });
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.box-card {
  padding-bottom: 5%;
  position: relative;
}

.contain {
  width: 90%;
  height: 100%;
  padding: 0 5%;
}
h3 {
  margin: 5px 0;
  margin-top: 10%;
}
.product_list span {
  font-weight: border;
  color: #409eff;
}
.swiper_add_imag {
  width: 400px;
  margin: 10px 0;
  display: flex;
}
.search_button {
  margin-left: 10px;
}
.product_d {
  margin-left: 30px;
  color: #606266 !important;
  display: none;
}
.product_list:hover .product_d {
  display: inline-block;
}
.swiper_img {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}
.swiper_img_list {
  position: relative;
  margin: 0 10px;
  width: 16%;
}
.swiper_img_list img {
  width: 100%;
}
.swiper_icon {
  display: none;
  position: absolute;
  left: 2%;
  top: 2%;
}
.swiper_img_list:hover .swiper_icon {
  display: inline-block;
}
.set_success {
  margin-top: 5%;
  margin-left: 10%;
}
.notice_list {
  width: 90%;
  margin-top: 2%;
}
.notice_D {
  display: none;
  margin-left: 20px;
}
.notice_list:hover .notice_D {
  display: inline-block;
}

.shade {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  background-color: #999;
  color: #fff;
  opacity: 0.8;
  font-size: 50px;
  text-align: center;
  line-height: 800%;
}
</style>